
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState("app", ["event"]),
    src () { return this.event?.theme?.banner?.url },
    bgColor () { return this.event?.theme?.bannerBgColor || "none" },
  },
}
