// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScxFT07J{background-color:var(--bg-color);background-image:var(--bg-img);background-position:50%;background-repeat:no-repeat;background-size:auto 100%;height:var(--banner-height);width:100%}@media(max-width:947.98px){.ScxFT07J{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"banner": "ScxFT07J"
};
module.exports = ___CSS_LOADER_EXPORT___;
